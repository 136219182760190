
import { Options, Vue } from "vue-class-component";
import { getSiteName } from "@/utilities/UpdatePageTitle";

@Options({
  components: {},
})
export default class Home extends Vue {
  data() {
    return {
      ZeroHeightLink: process.env.VUE_APP_ZERO_HEIGHT_LINK,
    };
  }
  beforeCreate() {
    document.body.className = "home";
  }

  mounted() {
    document.title = `Start ${getSiteName()}`;
  }

  unmounted() {
    document.body.className = "";
  }
}
