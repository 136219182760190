
import { Options, Vue } from "vue-class-component";
import Navbar from "@/components/Navbar.vue";
import LogRocket from "logrocket";

@Options({
  components: {
    Navbar,
  },
})
export default class Home extends Vue {}
if (process.env.NODE_ENV === "production") {
  LogRocket.init("msbcwv/iceberg");
}
