
import { Options, Vue } from "vue-class-component";

@Options({})
export default class Navbar extends Vue {
  data() {
    return {
      ZeroHeightLink: process.env.VUE_APP_ZERO_HEIGHT_LINK,
      ZHResourcesLink: process.env.VUE_APP_RESOURCES_LINK,
    };
  }
  // created() {
  //   if (true) {
  //     require("../../public/snow.js");
  //   }
  // }
}
